import React from 'react';
import ReactMarkdown from 'react-markdown';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/misc/seo';
import Container from 'src/components/widget/container';
import Divider from 'src/components/widget/divider';
import ExpandableSection from 'src/components/widget/expandable-section';
import PageHeader from 'src/components/widget/page-header';
import WideContainer from 'src/components/widget/wide-container';
import DesktopComponent from 'src/hocs/desktop-component';
import MobileComponent from 'src/hocs/mobile-component';
import { StaticContextType } from 'typings/custom';
import { IFaq } from 'typings/generated/contentful';

type Props = {
  pageContext: {
    reactContext: StaticContextType;
    faq: IFaq[];
    title: string;
  };
};

const FAQPage = ({ pageContext }: Props) => {
  const { faq, title, reactContext } = pageContext;
  return (
    <Layout context={reactContext}>
      <SEO description="" title={title} />
      <div className="flex justify-center">
        <Container>
          <PageHeader title={title} />
          <DesktopComponent>
            <div className="flex-col">
              {faq.map(({ fields: { question, answer }, sys }) => (
                <div key={sys.id}>
                  <div className="pt-4 font-bold text-x-orange">{question}</div>
                  <div className="py-4">
                    <ReactMarkdown className="markdown" linkTarget="_blank">
                      {answer}
                    </ReactMarkdown>
                  </div>
                  <Divider showOnDesktop />
                </div>
              ))}
            </div>
          </DesktopComponent>
          <MobileComponent>
            <div className="pt-4">
              {faq.map(({ fields: { question, answer }, sys }) => (
                <div key={sys.id}>
                  <ExpandableSection className="py-4" title={question}>
                    <div className="p-2 mb-2 text-white rounded bg-x-orange">
                      <ReactMarkdown className="markdown" linkTarget="_blank">
                        {answer}
                      </ReactMarkdown>
                    </div>
                  </ExpandableSection>

                  <WideContainer>
                    <Divider />
                  </WideContainer>
                </div>
              ))}
            </div>
          </MobileComponent>
        </Container>
      </div>
    </Layout>
  );
};

export default FAQPage;
