import { Asset } from 'contentful';
import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import { COLOR_GRAY } from 'src/constants';
import DesktopComponent from 'src/hocs/desktop-component';
import MobileComponent from 'src/hocs/mobile-component';
import { getFluidImage } from 'src/util/get-fluid-image';

import SEO from '../misc/seo';
import WideContainer from './wide-container';

interface Props {
  title: string | undefined;
  largeTitle?: boolean;
  description?: string | null | undefined;
  heroImage?: Asset;
}

const descriptionNode = (text) => (
  <div className="py-4 md:pl-0 ">
    <ReactMarkdown
      className="text-justify markdown"
      linkTarget="_blank"
      source={text}
    />
  </div>
);

function PageHeader({ title, description, heroImage }: Props): ReactElement {
  return (
    <div className="flex flex-col md:pb-8 md:space-x-4 md:flex-row">
      <div className={heroImage && 'md:w-1/2'}>
        <div className="text-5xl text-left md:leading-none title md:px-0">
          {title}
        </div>
        <DesktopComponent>
          {description && descriptionNode(description)}
        </DesktopComponent>
      </div>
      {heroImage && (
        <div className="md:w-1/2">
          <WideContainer>
            <GatsbyImage
              backgroundColor={COLOR_GRAY}
              className="w-screen h-64 md:mx-0 md:w-full md:rounded md:h-84"
              draggable={false}
              fadeIn={false}
              fluid={getFluidImage(heroImage)}
            />
          </WideContainer>
        </div>
      )}
      <MobileComponent>{descriptionNode(description)}</MobileComponent>
      <SEO description={description || ''} title={title} />
    </div>
  );
}

export default PageHeader;
